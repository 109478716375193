var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.SO_API_BASE_URL != null)?_c('v-app',{key:_vm.updateCounter,staticClass:"grey lighten-4",staticStyle:{"max-height":"100vh"}},[_c('license-notification'),(
      _vm.$router.currentRoute.name !== 'home' &&
      _vm.currentUser.role != 'PublicDashboard'
    )?_c('NavBar'):_vm._e(),_c('v-main',{class:{
      sientificLight: !this.darkmode && _vm.userSettings.theme != 'Colors',
      sientificDark: this.darkmode && _vm.userSettings.theme != 'Colors',
      backg: _vm.userSettings.theme == 'Colors' && !this.darkmode,
      backgdark: _vm.userSettings.theme == 'Colors' && this.darkmode,
    },staticStyle:{"padding":"0"}},[_c('router-view')],1),(_vm.message != '')?_c('v-snackbar',{attrs:{"bottom":"","right":"","timeout":3000,"color":_vm.alertType == 'error' ? 'red' : 'green'},model:{value:(_vm.refresh),callback:function ($$v) {_vm.refresh=$$v},expression:"refresh"}},[_vm._v(_vm._s(_vm.message)+" ")]):_vm._e(),(_vm.$router.currentRoute.name === 'dashboardChooser')?_c('v-footer',{attrs:{"app":""}},[_c('span',[_vm._v(" "+_vm._s(this.globalSettings.powered_by ? this.globalSettings.powered_by : "Nodeledge AB"))])]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }