import { mapState, mapGetters, mapActions } from "vuex";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import { EventTypes } from "./CsharpEnum";

export default {
  name: "eventListeningMixin",

  data() {
    return {
      hub: null,
      activeRoutes: [
        new RegExp("^/tag$"),
        new RegExp("^/tag/map$"),
        new RegExp("^/tagdetails/.+$"),
      ],
    };
  },

  computed: {
    ...mapState("configuration", { baseURL: "SO_API_BASE_URL" }),
    ...mapGetters("users", { hubToken: "token" }),
  },

  methods: {
    ...mapActions("event", { addToEvent: "addEvent" }),

    onEvent(payload) {
      if (payload.type == EventTypes.CHIRPSTACK) return;
      if (this.activeRoutes.some((x) => x.test(this.$router.currentRoute.path)))
        this.addToEvent(payload);
    },
  },

  async created() {
    if (this.hubToken === undefined || this.baseURL === undefined) {
      console.error("Could not find token for user to use for eventListening");
      return;
    }

    this.hub = new HubConnectionBuilder()
      .withUrl(this.baseURL + "/events", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => this.hubToken,
      })
      .configureLogging(LogLevel.Warning)
      .withAutomaticReconnect()
      .build();

    this.hub.on("BroadcastEvent", this.onEvent);
    await this.hub.start();
    this.hub.onreconnected(() => this.hub.on("BroadcastEvent", this.onEvent));
  },

  async beforeDestroy() {
    if (!this.hub) return;
    this.hub.off("BroadcastEvent", this.onEvent);
    await this.hub.stop();
    this.hub = null;
  },
};
