/**
 * Normalizes the attribute name for DB. This normalization needs to mirror the same function in Backend in TagAttribute.cs
 * @param inp
 * @returns
 */
export function normalize(inp) {
  return inp.trim().replaceAll(" ", "_").replaceAll("-", "_").toUpperCase();
}

/**
 * Converts tags.attribute array to a dictionary of k-v pairs
 * tags.attribute: [] => tags.attribute: {}
 * Needed to map attribute values
 * @param tags
 */
export function tagsWithAttributeDict(tags) {
  if (!tags) return [];

  let ts = tags;
  for (var tag of ts) {
    const obj = {};
    tag.attributes.forEach(
      (value) => (obj[value.normalizedName] = value.value)
    );
    tag.attributeDict = obj;
  }
  return ts;
}

export const getDefaultNodeTableHeaders = [
  {
    title: "",
    langLine: "tag.fields.deveui",
    key: "deveui",
    useLangLine: true,
  },
  {
    title: "",
    langLine: "tag.fields.nodeType",
    key: "nodeType",
    useLangLine: true,
  },
  {
    title: "",
    langLine: "tag.fields.name",
    key: "name",
    useLangLine: true,
  },
  {
    title: "",
    langLine: "tag.fields.type",
    key: "decoder",
    useLangLine: true,
  },
  {
    title: "",
    langLine: "tag.fields.battery",
    key: "battery",
    useLangLine: true,
  },
  {
    title: "RSSI",
    key: "rssi",
    useLangLine: false,
  },
  {
    title: "",
    langLine: "tag.fields.latestValue",
    key: "lastContact",
    useLangLine: true,
  },
  {
    title: "",
    langLine: "tag.fields.dataPoints",
    key: "count",
    useLangLine: true,
  },
  {
    title: "",
    langLine: "tag.fields.activeData",
    key: "active",
    useLangLine: true,
    sortable: false,
  },
  {
    title: "",
    langLine: "tag.fields.activeAlarm",
    key: "alarm",
    useLangLine: true,
    sortable: false,
  },
];
