import Repository from "../Repository";
import { apiConfig, getAuthHeader, company } from "../../_helpers/apiHelper";
import DateHelper from "../../_helpers/DateHelper";
import store from "../../stores/store";

const resource = "/tag";
const decoderResource = "/decoder";

export default {
  get(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}`, config);
  },

  create(user, applicationId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `/applications/${applicationId}/tag`,
      payload,
      config
    );
  },

  update(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${payload.deveui}`, payload, config);
  },

  delete(user, tagId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${tagId}`, config);
  },

  getById(user, tagId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${tagId}`, config);
  },

  getByCompanyId(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/company/${companyId}`, config);
  },

  getTagAttributesByCompanyId() {
    return Repository.get(
      `${resource}/company/${company()}/attributes`,
      apiConfig()
    );
  },

  getByCurrentCompany() {
    return Repository.get(`${resource}/company/${company()}`, apiConfig());
  },

  getKeys(user, tagId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${tagId}/keys`, config);
  },

  getAllKeys(user) {
    var config = { headers: getAuthHeader(user) };

    return Repository.get(`${resource}/keys/all`, config);
  },

  getTagWithMetaAndKeys(deveuiAndKeys) {
    return Repository.post(
      `${resource}/meta-with-keys`,
      deveuiAndKeys,
      apiConfig()
    );
  },

  uploadImage(user, tagId, image) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${tagId}/image`, image, config);
  },

  deleteImage(user, tagId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${tagId}/image`, config);
  },

  setDefaultKey(user, tagId, key) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `${resource}/${tagId}/default/${encodeURIComponent(key)}`,
      null,
      config
    );
  },

  getTagPool(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/pool`, config);
  },

  updateTagPoolStatus(user, deveui) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/pool/${deveui}`, null, config);
  },

  addTagKey(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/key/add`, payload, config);
  },

  updateTagKey(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/key/update`, payload, config);
  },

  deleteTagKey(user, deveui, key) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(
      `${resource}/key/delete/${deveui}/${encodeURIComponent(key)}`,
      config
    );
  },

  getKeyLabel(user, deveui, key) {
    if (user === undefined) user = store.getters["users/user"];

    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(
      `${resource}/key/label/${deveui}/${encodeURIComponent(key)}`,
      config
    );
  },

  getDecoders(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${decoderResource}`, config);
  },

  setData(user, tagId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${tagId}/tagdata`, payload, config);
  },

  getDetails(user, tagId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/detail/${tagId}`, config);
  },

  getTagWithInformation(deveui) {
    if (deveui === undefined || deveui === "") return {};

    return Repository.get(`${resource}/info/${deveui}`, apiConfig());
  },

  updateSparkplugMeta(user, deveui, meta) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${deveui}/sparkplug`, meta, config);
  },

  getPositions(deveui, from, to) {
    return Repository.get(
      `${resource}/${deveui}/positions/${encodeURIComponent(
        DateHelper.methods.toApiDate(from)
      )}/${encodeURIComponent(DateHelper.methods.toApiDate(to))}`,
      apiConfig()
    );
  },

  removePublishing(deveui, publishingType) {
    return Repository.delete(
      `${resource}/publishing/${deveui}/${publishingType}`,
      apiConfig()
    );
  },

  togglePublishKey(deveui, key) {
    return Repository.put(
      `${resource}/publish-key/toggle/${deveui}/${key}`,
      null,
      apiConfig()
    );
  },

  getActiveInactiveCount() {
    return Repository.get(
      `${resource}/active-inactive-count/${company()}`,
      apiConfig()
    );
  },
};
