import Repository from "../Repository";
import store from "../../stores/store.js";
import { ResourceTypes } from "../../_helpers/CsharpEnum";
import {
  apiConfig,
  company,
  user,
  getAuthHeader,
} from "../../_helpers/apiHelper";

const resource = "/report";

export default {
  getReport(reportId) {
    return Repository.get(`${resource}/${reportId}`, apiConfig());
  },

  getReports() {
    return Repository.get(`${resource}/company/${company()}`, apiConfig());
  },

  create(payload) {
    return Repository.post(`${resource}/${company()}`, payload, apiConfig());
  },

  update(reportId, payload) {
    return Repository.put(`${resource}/${reportId}`, payload, apiConfig());
  },

  delete(reportId) {
    return Repository.delete(`${resource}/${reportId}`, apiConfig());
  },

  multiExport(user, payload, companyId = null) {
    var config = {
      headers: getAuthHeader(user),
      responseType: "blob",
    };

    return Repository.post(
      `${resource}/multiexport/${companyId}`,
      payload,
      config
    );
  },

  getTemplate(reportTemplateId) {
    return Repository.get(
      `${resource}/template/${reportTemplateId}`,
      apiConfig()
    );
  },

  getTemplates() {
    return Repository.get(`${resource}/templates/${company()}`, apiConfig());
  },

  createTemplate(payload) {
    return Repository.post(
      `${resource}/template/${company()}`,
      payload,
      apiConfig()
    );
  },

  updateTemplate(reportTemplateId, payload) {
    return Repository.put(
      `${resource}/template/${reportTemplateId}`,
      payload,
      apiConfig()
    );
  },

  deleteTemplate(reportTemplateId) {
    return Repository.delete(
      `${resource}/template/${reportTemplateId}`,
      apiConfig()
    );
  },

  uploadTemplateFile(reportTemplateId, file) {
    return Repository.post(
      `${resource}/template/file/${reportTemplateId}`,
      file,
      apiConfig()
    );
  },

  getResources(type) {
    return Repository.get(
      `${resource}/resources/${company()}/${type}`,
      apiConfig()
    );
  },

  getResourcesByResourceIds(ids) {
    return Repository.post(
      `${resource}/resources/${company()}`,
      ids,
      apiConfig()
    );
  },

  getReportHistoryByCompany() {
    console.error("Not implemented yet");
    return;
  },

  getReportHistoryByUser() {
    console.error("Not implemented yet");
    return;
  },

  getHistories(resourceType, id = undefined) {
    let promise = undefined;
    switch (resourceType) {
      case ResourceTypes.COMPANY:
        promise = Repository.get(
          `${resource}/history/company/${company()}`,
          apiConfig()
        );
        break;
      case ResourceTypes.USER:
        promise = Repository.get(
          `${resource}/history/user/${user()}`,
          apiConfig()
        );
        break;
      case ResourceTypes.REPORT:
        if (id == undefined) {
          store.dispatch("alert/error", "Report ID is required");
          return [];
        }

        promise = Repository.get(
          `${resource}/history/report/${id}`,
          apiConfig()
        );
        break;
      default:
        store.dispatch("alert/error", "Unsupported resource type");
        break;
    }

    if (promise == undefined) return [];

    return promise
      .then((d) => d.data)
      .catch((e) => {
        console.error(e);
        return [];
      });
  },

  manualExport(reportId, start, end) {
    var range = {
      start: start,
      end: end,
    };

    return Repository.post(
      `${resource}/manual-export/${reportId}`,
      range,
      apiConfig()
    );
  },
};
