import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
import { saveAs } from "file-saver";
import { format } from "date-fns";

const ReportRepository = RepositoryFactory.get("report");

export const reports = {
  namespaced: true,
  state: {
    currentReport: {},
    reports: [],
    templates: [],
    status: {
      loading: false,
    },
  },

  actions: {
    setCurrentReport({ commit }, report) {
      commit("GET_CURRENT", report);
    },

    async getReports({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      await ReportRepository.getReports()
        .then((reports) => {
          commit("GET_REPORTS", reports.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getReport({ dispatch, commit, rootState }, { reportId }) {
      commit("LOADING", true);

      await ReportRepository.getReport(reportId)
        .then((report) => {
          commit("GET_CURRENT", report.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async create({ dispatch, commit, rootState }, { payload }) {
      commit("LOADING", true);

      await ReportRepository.create(payload)
        .then(() => {
          commit("LOADING", false);
          dispatch("getReports");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async update({ dispatch, commit, rootState }, { reportId, payload }) {
      commit("LOADING", true);

      await ReportRepository.update(reportId, payload)
        .then(() => {
          commit("LOADING", false);
          dispatch("getReports");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async delete({ dispatch, commit, rootState }, { reportId }) {
      commit("LOADING", true);

      await ReportRepository.delete(reportId)
        .then(() => {
          commit("LOADING", false);
          dispatch("getReports");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async multiExport({ dispatch, commit, rootState }, { payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      var companyId = null;
      await ReportRepository.multiExport(user, payload, companyId)
        .then((res) => {
          var date = format(new Date(), "YYY-MM-dd");
          saveAs(new Blob([res.data]), `SensorOnline-Report-${date}.xlsx`);
        })
        .catch((res) => {
          if (res.response.status === 404) {
            dispatch("alert/error", i18n.t("report.noData"), { root: true });
          } else
            dispatch("alert/error", i18n.t("report.downloadFailed"), {
              root: true,
            });
        });

      commit("LOADING", false);
    },

    async getTemplate({ dispatch }, reportSoTemplateId) {
      return await ReportRepository.getTemplate(reportSoTemplateId)
        .then((templates) => {
          return templates.data;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
          return {};
        });
    },

    async getTemplates({ dispatch, commit }) {
      await ReportRepository.getTemplates()
        .then((templates) => {
          commit("GET_TEMPLATES", templates.data);
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async createTemplate({ dispatch }, payload) {
      return await ReportRepository.createTemplate(payload)
        .then((resp) => {
          return resp.data;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
          return undefined;
        });
    },

    async updateTemplate({ dispatch }, payload) {
      if (payload?.reportSoTemplateId === undefined) {
        console.error("reportTemplateId is required");
        return false;
      }

      return await ReportRepository.updateTemplate(
        payload.reportSoTemplateId,
        payload
      )
        .then(() => {
          return true;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
          return false;
        });
    },

    async deleteTemplate({ dispatch }, reportTemplateId) {
      return await ReportRepository.deleteTemplate(reportTemplateId)
        .then(() => {
          return true;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
          return false;
        });
    },

    async uploadTemplateFile({ dispatch }, { reportSoTemplateId, file }) {
      try {
        let resp = await ReportRepository.uploadTemplateFile(
          reportSoTemplateId,
          file
        );
        if (resp.status !== 204)
          throw new Error("Error uploading template file");

        return true;
      } catch {
        dispatch("alert/error", i18n.t("common.error"), { root: true });
        return false;
      }
    },

    async getResources({ dispatch }, type) {
      return await ReportRepository.getResources(type)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
          return [];
        });
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    GET_REPORTS(state, reports) {
      state.reports = reports;
    },

    GET_CURRENT(state, report) {
      state.currentReport = report;
    },

    GET_TEMPLATES(state, templates) {
      state.templates = templates;
    },
  },
};
