import Repository from "../Repository";
import { apiConfig, company } from "../../_helpers/apiHelper";

const resource = "/tagJournalFile";

export default {
  uploadJournalFile(payload) {
    return Repository.post(
      `${resource}/upload/${company()}`,
      payload,
      apiConfig()
    );
  },
};
