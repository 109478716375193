import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
const AlarmRepository = RepositoryFactory.get("alarm");

export const alarms = {
  namespaced: true,
  state: {
    currentAlarm: {},
    activeAlarms: [],
    alarms: [],
    settingResponse: {},
    status: {
      loading: false,
    },
  },

  actions: {
    async testSettings({ commit, rootState }, payload) {
      var user = rootState.users.currentUser;

      await AlarmRepository.testSettings(user, payload)
        .then((res) => {
          commit("SET_SETTING_RESPONSE", res.data);
        })
        .catch((res) => {
          commit("SET_SETTING_RESPONSE", res.response.data);
        });
    },

    async getAlarms({ commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AlarmRepository.get(user, rootState.users.userCompany.companyId)
        .then((alarms) => {
          commit("GET_ALARM", alarms.data);
          commit("LOADING", false);
        })
        .catch(() => {
          commit("LOADING", false);
        });
    },

    async createAlarm({ dispatch, commit, rootState }, { alarmPayload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AlarmRepository.create(
        user,
        rootState.users.userCompany.companyId,
        alarmPayload
      )
        .then((alarm) => {
          dispatch("getAlarms");
          commit("CREATE_ALARM", alarm.data);
          commit("LOADING", false);
          dispatch("alert/success", i18n.t("common.created"), { root: true });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async updateAlarm({ dispatch, commit, rootState }, { payload, alarmId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AlarmRepository.update(
        user,
        rootState.users.userCompany.companyId,
        alarmId,
        payload
      )
        .then(() => {
          commit("LOADING", false);
          dispatch("alert/success", i18n.t("common.updated"), { root: true });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });

      await dispatch("getAlarms");
    },

    async setCurrentAlarm({ commit }, alarm) {
      commit("SET_CURRENT", alarm);
    },

    async getAlarmById({ commit, rootState }, alarmId) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AlarmRepository.getById(user, alarmId)
        .then((alarm) => {
          commit("SET_CURRENT", alarm.data);
          commit("LOADING", false);
        })
        .catch(() => {
          commit("LOADING", false);
        });
    },

    async getActiveAlarms({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AlarmRepository.getActiveAlarms(
        user,
        rootState.users.userCompany.companyId
      )
        .then((alarms) => {
          commit("GET_ACTIVE_ALARM", alarms.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getAlarmHistory({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AlarmRepository.getAlarmHistory(
        user,
        rootState.users.userCompany.companyId
      )
        .then((alarms) => {
          commit("GET_ACTIVE_ALARM", alarms.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async setAlarmStatus(
      { dispatch, commit, rootState },
      { activeAlarmId, comment }
    ) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AlarmRepository.setActiveAlarmStatus(user, activeAlarmId, comment)
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });

      await dispatch("getActiveAlarms");
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },

    async removeAlarm({ dispatch, commit, rootState }, id) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AlarmRepository.delete(user, id)
        .then(() => {
          dispatch("getAlarms");
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async duplicateAlarm({ dispatch, commit, rootState }, id) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AlarmRepository.duplicate(user, id)
        .then(() => {
          dispatch("getAlarms");
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    clearTestResponse({ commit }) {
      commit("SET_SETTING_RESPONSE", {});
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    SET_CURRENT(state, alarm) {
      state.currentAlarm = alarm;
    },

    GET_ALARM(state, alarms) {
      state.alarms = alarms;
    },

    GET_ACTIVE_ALARM(state, alarms) {
      state.activeAlarms = alarms;
    },

    CREATE_ALARM(state, alarm) {
      state.currentAlarm = alarm;
    },

    SET_SETTING_RESPONSE(state, res) {
      state.settingResponse = res;
    },

    // eslint-disable-next-line
    RESET_STATE(state) {
      state = {
        currentAlarm: {},
        activeAlarms: [],
        alarms: [],
        status: {
          loading: false,
        },
      };
    },
  },

  getters: {
    triggeredAlarms: (state) => {
      return state.alarms
        .filter((alarm) => alarm.active)
        .filter((alarm) => {
          const isAlarmActive = alarm.activeAlarms.length > 0;
          return isAlarmActive || alarm.flagging;
        })
        .sort((a, b) => {
          return b.activeAlarms.length - a.activeAlarms.length;
        });
    },
  },
};
