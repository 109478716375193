export default {
  routes: {
    home: "Home",
    login: "Login",
    register: "Register",
    registerCompany: "New Company",
    logout: "Logout",
    landing: "Start",

    company: "Company",
    dashboard: "Dashboard",
    application: "Applications",
    alarm: "Alarm Management",

    user: "Users",
    tagdata: "Data",
    tag: "Nodes",
    script: "Script Engine",
    report: "Reports",
    contact: "Contact",
    apiRef: "API-Documentation",
    shop: "Buy nodes",
    manual: "Manual",
    userEdit: "Account",
    settings: "Settings",
    signalTest: "Field testing",
    gateway: "Gateways",
    news: "News",
    roles: "Roles",

    superadmin: "Admin",
  },

  integrations: {
    title: "Integrations",
    chargeAmps: {
      fields: {
        apiKey: "API Key",
        organizationId: "Organization ID",
        useRfid: "Use RFID as Identifier",
      },

      organizationIdDescription: "Organization ID for ChargeAmps",
      useRfidDescription:
        "Use RFID as identifier, this makes it possible to categorize the data by the user that has utilized the charging station instead of by the charging station itself or its connector",
      apiKeyDescription:
        "API Key for ChargeAmps that is provided by ChargeAmps support",
    },
    chirpStack: {
      fields: {
        name: "Integration Name",
        brokerHostname: "Broker Hostname",
        version: "ChripStack Version",
        topics: {
          gateway: "Topic for Gateway events",
          device: "Topic for Device events",
        },
      },
      gatewayTopicHint:
        "+ for single-layer wildcard, # for multi-layer wildcard",
      deviceTopicHint:
        "+ for single-layer wildcard, # for multi-layer wildcard",
      versions: {
        0: "V3",
        1: "V4",
      },
    },
  },

  resourceType: {
    select: "Select Resource Type",
    selected: "Selected Resources",
    resource: "Resource",
    add: "Add",

    type: {
      tag: "Node",
      gateway: "Gateway",
      sensor: "Sensor",
      company: "Company",
      user: "User",
      report: "Report",
      auditLog: "Auditlog",
    },
  },

  auditLog: {
    companyAuditLogTitle: "Company Audit Log",
    title: "Audit Log",
    from: "From",
    to: "To",
  },

  actions: {
    title: "Actions",
    toolbar: "Actions",
    create: "Create Action",
    edit: "Edit Action",
    delete: "Delete Action",
    deleteError: "Could not delete action",
    deleteSuccess: "Deleted",
    notify: "Notify-methods",
    noCooldownActive: "Not on Cooldown",
    onCooldown: "On Cooldown",
    noCooldownDescription: "There is no active cooldowns to take action on",
    resetCooldownActionDescription:
      "Reset the cooldown for this action, this makes it possible to trigger the action again",
    resetCooldown: "Reset cooldown",
    resetAllCooldown: "Reset all cooldowns",

    config: {
      emailTitleConfigDescription:
        "Email title template makes it possible to customize the title of the email (Example to include node names in the title: 'Nodes {node_name} is offline')",
      availableTitleVariables: "Available variables:",
      variables: {
        0: "{node_name}, {node_deveui} and {node_name_and_deveui}",
        1: "{node_name}, {node_deveui} and {node_name_and_deveui}",
        2: "{node_name}, {node_deveui} and {node_name_and_deveui}",
        3: "{gateway_name}, {gateway_eui} and {gateway_name_and_eui}",
        4: "{gateway_name}, {gateway_eui} and {gateway_name_and_eui}",
      },
    },

    fields: {
      title: "Title",
      description: "Description",
      eventType: "Event type",
      actionTypesString: "Action type",
      sms: "Sms",
      email: "Email",
      cooldownOnAction: "Cooldown on action",
      cooldownMinutes: "Cooldown in minutes",
      cooldownUntil: "Cooldown until",
      manualCooldown: "Manual Acknowledge",
      duration: "Duration",
      emailTitleTemplate: "Email title template",
      isOnCooldown: "Cooldown",
      identifier: "Identifier",
    },

    hints: {
      duration: "Duration in minutes for the selected event",
      manualCooldown:
        "When activated, the user must manually acknowledge the event for a new event to be triggered.",
    },

    details: {
      edit: "Edit Action",
      cooldowns: "Actions on cooldown",
      history: "Action history",
    },

    resources: {
      title: "Resources",
      create: "Create Resource",
      edit: "Edit Resource",
      delete: "Delete Resource",
      deleteError: "Could not delete resource",
      deleteSuccess: "Deleted",
      selectResource: "Choose Resource",
      selectAll: "Select all",

      fields: {},
    },

    actions: {
      title: "Actions",
      description: "The actions is what happens when an event is triggered",
      sms: "SMS",
      email: "Email",
      addToAction: "Add to action",
    },

    errors: {
      noActionIdFound: "No Action with the ID specified found",
    },

    eventTypes: {
      0: "Data Ingest Error",
      1: "Node Offline",
      2: "Add Node to Action",
      3: "Gateway Offline",
      4: "Add Gateway to Action",
    },
  },

  widget: {
    weather: {
      pickTitle: "Position for SMHI Weather-data",
    },

    form: {
      step1: "Widget",
      step2: "Slots / Keys",
      step3: "Attributes",
    },
  },

  opc: {
    title: "OPC-UA",
    server: {
      title: "OPC-UA Servrers",
      autocomplete: "Select a OPC-Server",
      fields: {
        opcServerId: "OPC Server ID",
        serverUrl: "Server URL",
        useUsernameAndPassword: "Använd Användarnamn och Lösenord",
        updatedAt: "Uppdaterad",
        createdAt: "Skapad",
      },
    },

    node: {
      fields: {
        serverId: "Server",
        key: "Key",
        nodeId: "NodeID",
      },

      hints: {
        nodeId: "Example, ns=1;i=1001",
      },
    },
  },

  modbus: {
    title: "Modbus",

    master: {
      title: "Modbus Masters",
      create: "Add new Master",
      tryReconnect: "Try to reconnect",

      fields: {
        modbusMasterId: "Master ID",
        name: "Title",
        host: "IP",
        port: "Port",
        status: "Status",
      },
    },

    register: {
      title: "Modbus Register",
      create: "Add a new register",
      poll: "Poll registers",
      polledValue: "Polled value",

      fields: {
        modbusRegisterId: "Register ID",
        modbusMasterId: "Master ID",
        master: "Master",
        slave: "Slav ID",
        nodeId: "Node ID",
        key: "Sensor key",
        register: "Register",
        pollInterval: "Poll interval (Seconds)",
        registerType: "Register-type",
        valueType: "Value-type",
        action: "Poll/Push",
        byteOrder: "Byte-order",
        createdAt: "Created",
        updatedAt: "Updated",
      },

      valueTypes: {
        0: "FLOAT",
        1: "DOUBLE",
        2: "INT16",
        3: "UINT16",
        4: "INT32",
        5: "UINT32",
        6: "BOOL",
      },

      types: {
        0: "Holding Register",
        1: "Coil",
        2: "Analog Input",
        3: "Digital Input",
      },

      byteOrders: {
        0: "ABCD",
        1: "DCBA (Default)",
        2: "BADC",
        3: "CDAB",
      },
    },

    validation: {
      required: "This field is required",
      slaveMinVal: "SlavID must be greater than 1",
      slaveMaxVal: "SlavID must be less than 255",
      registerMaxVal: "Register must be less than 67000",
      registerMinVal: "Register must be greater or equal to 0",
      pollIntervalMin: "Poll Interval cannot be less than 60 seconds",
      registerAllreadyReadFrom: "This register is allready in use",
    },
  },

  spotIo: {
    fields: {
      key: "SpotIO Key",
    },
    validation: {
      spotIoKeyRequired: "'SpotIO Key' is required",
      spotIoKeyUnique: "'SpotIO Key' must be unique for the Node",
    },
  },

  templateDashboard: {
    title: "Dashboard Templates",
    deleteError: "Could not delete template",
    deleteSuccess: "Deleted",

    saveDashboard: "Save",

    defaults: {
      name: "New template dashboard",
    },

    fields: {
      name: "Name",
      dashboardType: "Type",
      createdAt: "Created",
      updatedAt: "Updated",
    },

    addSlot: "Add Node-Slot",
    numberOfAvailableSlots: "Amount of Node-Slots: {num}",

    dashboardTypeTitle: "Dashboard type",
    dashboardType: {
      0: "Full Dashboard",
      1: "Side / Map Dashboard",
      2: "Node Dashboard",
    },

    slots: {},
  },

  slot: {
    addHint: "Add a Node Slot",
    resetSlots: "Reset all Slots",
    slotIndex: "Slot Index",
    priorityTitle: "Slot priority",
    priorityDescription:
      "Slot priority is used to select which sensors that should be showed first",
  },

  colorPicker: {
    colorForExpressionLabel: "Color for expression",

    so_style_meta_title_text: "Title text color",
    so_style_meta_title_background: "Title background color",
    so_style_meta_content_title: "Content text color",
    so_style_meta_content_background: "Content background color",
    so_style_meta_fill_level_color: "Color of fill-level",
    so_style_meta_color_for_expression: "Color for value",
    so_style_meta_action_for_content: "Color for value",

    iconColor: "Icon color",
    backgroundColor: "Background color",
  },

  camOnline: {
    servers: "Cam-Online Servers",
    couldNotFetchServers: "Could not fetch CamOnline Servers",
    couldNotCreateServer: "Something went wrong when creating CamOnline Server",
    couldNotDeleteServer: "Something went wrong when deleting CamOnline Server",

    serverSelect: "Choose Cam-Online Server",
    streamSelect: "Choose a camera",
    createDialogTitle: "Add a new Server",
    tabTitle: "Cam-Online",
    fields: {
      url: "Url (Ex: https://portal.cam-online.se)",
      accessToken: "AccessToken",
      isGlobal: "Global Server",
    },
  },
  roles: {
    toolbarTitle: "Roles",
    permissionCounts: "Permissions",

    missingRecommendedTitle:
      "Seems like you are missing some Recommended permissions do you want to add them?",
    missingRecommendedButton: "Click Me!",

    pickPermissionsTitle: "Pick Permissions for this Role",
    pickPermissionsDesc:
      "When picking Permissions you might encounter <b class='yellow--text text--darken-3'>Orange</b> Permissions. These Permissions are <b>Recommended</b> to make the base functionallity work. You can choose one of the default roles below to use them as a template.",

    layerTypes: {
      0: "Frontend Essentials",
      1: "Frontend",
      2: "Backend",
    },

    fields: {
      name: "Role Name",
      permissionCount: "Number of Permissions",
      isDefault: "Is Default",
      isSuper: "Is Admin",
    },
  },

  eventhub: {
    waiting: "Waiting for new events...",

    copySuccess: "Payload is in the clipboard",
    copyError: "Could not copy the Payload to clipboard",

    resumeMessages: "Go back to top",

    events: {
      data: "New Data",
      endpoint: "Endpoint event",
    },

    eventType: {
      0: "New Data",
      1: "Endpoint",
      2: "Attribute change",
      3: "ChirpStack message",
    },
  },

  chirpStackHub: {
    resumeEventFlow: "Return to live feed",
    tabHeader: {
      0: "Gateway Events",
      1: "Gateway Events",
      2: "Device Events",
      3: "Device Events",
      10: "Data Events",
      20: "Attribute Events",
    },
    updatedValue: "on:",
    searchTitle: "Filter events by text",
  },

  downlinks: {
    editorTitle: "Downlinks",

    validation: {
      port: "Port must be greater than 0",
    },
  },

  conditionTree: {
    selectValueTypeTitle: "Select value type",
    selectType: "Select type",
    selectValue: "Select value",
    chooseSensor: "Select Sensor",

    textInputPlaceholder: "Text value",
    numberInputPlaceholder: "Number value",

    types: {
      0: "Sensor Value",
      1: "Text",
      2: "Number",
      3: "Time",
      4: "No Value",
    },

    ops: {
      greater: "Greater than",
      less: "Less than",
      equal: "Equal to",
      greaterOrEqual: "Greater or equal than",
      lessOrEqual: "Less or equal than",
      notEqual: "Not",
    },
  },

  accessToken: {
    title: "Access Tokens",
    newToken: "Create a Token",
    cardNewTitle: "New Token",
    cardTitle: "Access Token",
    fields: {
      id: "Id",
      label: "Title",
      token: "Token",
      isCompany: "Company Token",
    },
  },

  news: {
    cannotGetNews: "Could not fetch the news from Nodeledge AB.",
  },

  gateway: {
    toolbar: "Gateways",
    toolbarTitle: "Gateways",
    bestGw: "Best gateway",

    pickLocationButton: "Set latitude and longitude",
    fields: {
      name: "Name",
      macAddress: "GatewayEUI",
      latitude: "Latitude",
      longitude: "Longitude",
      createdAt: "Created",
      lastSeen: "Last Seen",
    },
  },

  signalTest: {
    toolbarTitle: "Field tests",
    startTest: "Start a new test",
    endTest: "Complete test",
    peekTest: "Look at test",
    createToolbar: "Start a new test",
    rawData: "Raw data",
    updateTimer: "Update timer",

    fields: {
      testId: "Test ID",
      description: "Description",
      startTest: "Start",
      endTest: "End",
      active: "Active",

      avgSnr: "Average SNR",
      avgRssi: "Average RSSI",

      data: {
        rssi: "RSSI",
        snr: "SNR",
        lat: "LATITUDE",
        lng: "LONGITUDE",
        createdAt: "TIME",
        lastGateway: "GATEWAY",
        battery: "BATTERY",
      },
    },

    tab: {
      map: "MAP",
      data: "STATS",
    },
  },

  trend: {
    res: {
      week: "Resolution: Week",
      day: "Resolution: Day",
      hour: "Resolution: Hour",
      five: "Resolution: 5 Hours",
    },
  },

  notifications: {
    clearNotifications: "Mark all as read",
    noNotifications: "You are all up to date.",
  },

  landing: {
    slogan: "VISUALIZATION MADE EASY",
    or: "OR",
    key: {
      title: "Key features",
      1: "Nodemanagement",
      2: "Visualization",
      3: "Custom dashboards",
      4: "Alarm management",
      5: "Personnel management",
      6: "Script engine",

      description: {
        1: "Manage sensors for handling data",
        2: "Get your sensor data visualized the way you like to observe the data",
        3: "Create your own dashboard specialized for YOUR area of ​​use",
        4: "Alarm management for critical use cases",
        5: "To manage the personnel of your company",
        6: "Advanced users can use the script engine to manipulate data",
      },
    },

    usecases: {
      title: "Use cases",
      1: "Environment sensors",
      2: "Electricity sensors",
      3: "Movement sensors",
      4: "Temperature sensors",

      description: {
        1: "Great fit for smart farming solutions like Ljusgarda making use of the portal. The alarm feature of SENSORONLINE is the key to keeping your assets safe.",
        12: "Read more about Ljusgarda",
        2: "Measure current for streetlights in your city. With the script engine to make sense of the data gathered.",
        3: "Measure how many people or cars are leaving or entering your facility.",
        4: "Of course temperature measurements are a key part of our platform. Many companies use and keep track of their asset's temperatures.",
      },
    },

    help: {
      title: "Contact us",

      sensors: {
        title: "Need to get some new nodes?",
        description:
          "If you need any help to solve a problem, be it what sensor to use or how you could visualize your data in a better way. Don't be afraid to reach out to us so we can make your life easier. \n You can check out our portfolio of ",
        store: "nodes that we sell here at Nodeledge AB.",
      },

      api: {
        title: "API for your convenience",
        description:
          "You can of course get or insert data from your other applications to the platform, check out our API endpoints ",
      },

      contact: {
        title: "Are you interested? Contact us!",
        siencepark: "Skövde Science Park",
        address: "Kaplansgatan 16B",
        postal: "521 32 Skövde",
        country: "Sweden",
        phoneTitle: "Phone",
        phonenumber: "+46 (0) 500 600 022",
        phonenumber2: "+46 (0) 733 165 100",
        emailTitle: "Email",
        email: "info@sensor-online.se",
      },
    },
  },

  imageMap: {
    chooseTag: "Choose type of marker",
  },

  log: {
    type: {
      sms: "SMS",
      app: "Application",
      warning: "Warning",
      error: "Error",
      userevent: "User event",
      login: "Login",
    },

    logTable: {
      title: "Log",
    },

    activeAlarms: {
      title: "Active alarms",
    },

    activeUsersTable: {
      title: "Logged in users",
    },

    fields: {
      type: "Type",
      user: "User",
      message: "Message",
      createdAt: "Created",

      status: "Status",
      lastAction: "Event",
      lastSeen: "Timestamp",

      alarm: {
        name: "Name",
        value: "Value",
        messageSent: "Sent notification",
      },
    },
  },
  common: {
    yes: "Yes",
    no: "No",
    on: "On",
    off: "Off",
    timestamp: "Timestamp",
    lang: "Language",
    error: "An error occured, try again later",
    errorDownload: "Unable to download file try again later",
    edit: "Edit",
    delete: "Delete",
    reset: "Reset",
    purge: "Erase",
    submit: "Send",
    create: "Create",
    update: "Update",
    updateAll: "Update all",
    get: "Get",
    run: "Run",
    address: "Address",
    link: "Link",
    height: "Height",
    width: "Width",
    new: "New",
    value: "Value",
    newValue: "New Value",
    nothingToSee: "Nothing to see here",
    next: "Next",
    label: "Label",
    editLabel: "Edit Label",
    explanation: "Explanation",
    clear: "Clear",
    never: "Never",
    upload: "Upload",
    click: "Click",
    required: "Required",
    various: "(Various)",
    system: "System",
    download: "Download",
    default: "Default",

    previousStep: "Back",
    hours: "Hours",
    mintues: "Minutes",

    search: "Search",
    close: "Close",
    cancel: "Cancel",
    success: "Successful!",

    login: "Login",
    register: "Enroll",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    newPassword: "New Password",
    forgotPassword: "Forgot Password",

    createdAt: "Created",
    updatedAt: "Updated",

    save: "Save",
    show: "Show",
    updated: "Updated",
    created: "Created",
    deleted: "Erased",
    back: "Return",
    send: "Send",
    refresh: "Refresh",

    preview: "Preview",
    previewSpacing: "Space for other modules",

    saveColor: "Save color",
    alarmColorPrecedence:
      "Alarm colors will always take precedence over manually picked colors, force override in attributes if this is not what you want.",

    add: "Add",

    settings: "Setup",

    noTagOrAppChoosen: "Node and/or application missing",
    noDataFound: "No data found",
    darkTheme: "Dark theme",

    active: "Active",
    notActive: "Inactive",

    statusActive: "Active",
    statusInactive: "Inactive",

    sent: "Sent",
    notSent: "Unsent",

    loginOrRegister: "Login or enroll",

    copy: "Copy",
    verify: "Are you sure you want to continue?",
    verifyDelete: "Are you sure you want to remove this resource?",

    disagree: "No",
    accept: "Yes",

    or: "Or",

    registerCompany: "Create new company",

    dashboard: "Go to Dashboard",
    switchCompany: "Switch Company",

    inactiveSensors: "Inactive Sensors",
    activeSensors: "Active Sensors",
    sensorStatus: "Node-status",
    sensorName: "Node-name",

    inactiveGateways: "Inactive Gateways",
    activeGateways: "Active Gateways",
    gatewayStatus: "Gateway-status",

    updatesPending: "Changes awaiting refresh",
    pickLocationConfirm: "Do you want to save the position of this resource?",

    toFewBytes: "To few bytes",
    byteInputRequired: "You need to fill this field",

    getMorePages: "Load more",

    size: "Size",
    test: "Test",

    select: "Select",
    selected: "Selected",

    searchType: {
      TAG: "Nodes",
      ALARM: "Alarms",
      APPLICATION: "Applications",
      DASHBOARD: "Dashboards",
      GATEWAY: "Gateways",
      SCRIPT: "Scripts",
      ATTRIBUTE: "Attributes",
    },

    dateLabel: "Date",
    timeLabel: "Time",

    time: {
      day: "Day",
      hour: "Hour",
      quarterHour: "Quarter-hour",
      minute: "Minute",
    },

    days: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
    },

    dateRange: {
      0: "Last hour",
      1: "Last 5 hours",
      2: "Last 24 hours",
      3: "Last 7 days",
      4: "Last 30 days",
    },

    dateRangeShort: {
      0: "1H",
      1: "5H",
      2: "24H",
      3: "7D",
      4: "30D",
    },

    staticinformation: {
      datapoints: "Datapoints last 24 hours",
      inactiveSensors: "Inactive sensors",
      activeAlarms: "Active alarms",
      datapointsLastWeek: "Datapoints last week by day",
      weekday: "Weekday",
    },

    errors: {
      getDecoders: "Could not fetch decoders",
      backendConnection: "No connection to backend could be established.",
    },

    actions: {
      createCategory: "Create Category",
      editCategory: "Edit Category",
      remove: "Remove",

      createDashboard: "Create Dashboard",
      copyDashboard: "Copy Dashboard",
    },

    iconSelector: {
      selectIcon: "Choose Icon",
      searchPlaceholder: "Search for Icons",
    },
  },

  settings: {
    title: "Settings",
    userSettings: "User Settings",
    themes: "Themes",

    company: {
      title: "Company Settings",
      general: "General settings",
      branding: "White label",
      brandingTitle: "White Label Title",
    },

    data: {
      toolbarTransparancy: "Transparent toolbar",
      stickySideMenu: "Sticky sidemenu",
    },

    global: {
      name: "Global Settings",
      branding: "Branding",
      powered_by: "Powered By",
      SMS_PROVIDER: "SMS Provider",
      SMS_IP1_FROM: "IP1 Sender name",
      SMS_IP1_USERNAME: "IP1 Username",
      SMS_IP1_PASSWORD: "IP1 Password",
      SMS_TWILIO_ACCOUNTSID: "Twilio AccountSID",
      SMS_TWILIO_ACCESSTOKEN: "Twilio AccessToken",
      SMS_TWILIO_NUMBER: "Twilio Number",
    },
  },

  companysetting: {
    field: {
      smsprovider: "SMS Provider",
      alarmTitle: "Alarm",
      inactivityAlarmContact: "Mail for inactivity alarm",
      nodeTitle: "Node Settings",
      nodeAlias: "Node Alias",
      mapTitle: "Map Settings",
      coordinates: "Latitude & Longitude in DD-format",
      companyLocation: "Default coordinates for the company",
      mapSettings: {
        title: "Default map settings",
        attributeName: "Attribute to be displayed by marker",
        display: "Display this type by default",
        intervalSlider: "Preview of the inactivity slider",
        sliderInputLabel: "Modify the steps on the slider",
        sliderInputHint:
          "The steps needs to be in full hours and are delimited by ,",
      },

      hint: {
        inactivityAlarmContact:
          "Email address to recieve alarms when nodes have been inactive for at least 6 hours",
        nodeAlias: "This assists in suggesting and creating the node name",
      },

      ip1: {
        username: "IP1 Username",
        password: "IP1 AccessToken / Password",
        from: "Sender Name",
        hint: {
          from: "This is a name that will be used when SensorOnline is sending SMS",
          username:
            "If you want to find your username please visit the documentation: https://docs.sensor-online.se",
          password:
            "If you want to find your username please visit the documentation: https://docs.sensor-online.se",
        },
      },

      table: {
        title: "Table Settings",
        subtitle:
          "The Nodetable can be modified by adding/deleting columns and by dragging the column names into desired orders.",
        displayed: "Displayed",
        create: {
          title: "New Table Column",
          columntitle: "Optional: Custom Column Name",
          placeholder: "Select Table Column",
        },
      },

      twilio: {
        number: "Twilio Number",
        accountSid: "Twilio AccountSID",
        accessToken: "Twilio AccessToken",
        hint: {
          number:
            "A number you own on twilio that you are able to send messages with.",
          accountSid:
            "You can find more information where to find AccountSID in our documentation: https://docs.sensor-online.se",
          accessToken:
            "You can find more information where to find AccessToken in our documentation: https://docs.sensor-online.se",
        },
      },
    },
  },

  map: {
    create: "Create Map",
    setPosFormTitle: "Choose sensor to change position",
    currentPosition: "Current position",
    displayHistory: "Historic positions",
    useNodePosition: "Use the Nodes position",
    dateTimeSpanSelection: "Date to show",
    dateFrom: "Start date",
    dateTo: "End date",
    clearPositions: "Clear routes",
    stepPositions: "Step through positions",
    select: {
      node: "Select Node",
      dashboard: "Select Dashboard",
    },
    iconStyling: "Icon Styling",
    livemap: {
      legend: {
        title: "Description",
        description: {
          1: "Press a node to get more detailed information.",
          2: "The color and the value inside the nodes indicates different things depending on their node types.",
          3: "For Lorawan the color indicates a calculated combined RSSI and SNR status. For all other nodes the color indicates their latest data transmission. The value inside the nodes are whichever attribute is linked to that node type in company settings",
        },
        settingsTitle: "Settings",
        typeSelectionPlaceholder: "Select node types to view",
        intervalSliderLabel: "Set timespan for inactivity on map",
      },
    },
    picklocation: {
      legend: {
        title: "Description",
        description: {
          1: "The current position is marked on the map with a fixed marker.",
          2: "Klick on the map to pick a position. You may drag around the placed marker",
        },
      },
    },
    alltags: "Positions",
  },

  dashboard: {
    name: "Dashboards",
    selectDashboard: "Dashboards",
    createTitleClean: "Create new Dashboard",
    copyDeleteTitle: "Delete or copy dashboard",
    createTitle: "Or create a new dashboard",
    chooseTitle: "Choose dashboard",
    changeTitle: "Switch dashboard",
    navigationTitle: "Navigation menu",

    fields: { name: "Name" },
    nameReq: "This field must be filled in",

    dashboardType: {
      1: "Standard Dashboard",
      2: "Map Dashboard",
      3: "Image-Map Dashboard",
    },

    categories: {
      create: "Create Category",
      title: "Title",
      icon: "Icon",
    },

    copy: {
      tooltip: "Kopiera widget",
    },

    public: {
      title: "Public dashboards",
      create: "Create a public dashboard",
      createNew: "Create a new public dashboard",
      copiedSuccessful: "Copied link to clipboard",
      copiedUnsuccessful: "Could not copy link {link}",
      URL: "Dashboard URL",
      yes: "Yes",
      no: "No",
      remove: "Remove",

      fields: {
        host: "Host",
        expiresAt: "Valid until",
        expired: "Active",
        copyAction: "Link",
        dashboardName: "Dashboard",
      },
    },
  },

  module: {
    noData: "Could not find any data",
    create: "Create a new element",
    edit: "Edit element",
    metas: "Attributes",

    colorFormTitle: "Color settings",

    chart: {
      types: {
        line: "Line",
        area: "Area",
        bar: "Bar",
        scatter: "Scatter",
        vertical: "Vertical",
        trend: "Trend Chart",
      },
    },

    mapMarkers: {
      node: "Pin with node info",
      sensor: "Text label with latest sensor value",
    },

    meta: {
      label: "Attributes",
      save: "Save attributes",
      opacity: "Opacity",

      paletteToModuleMode: "Change Module Colors",
      paletteToCardMode: "Change Card Colors",

      valueMatch: "Attribute 1",
      value: "Attribute 2",
      key: "Sensor",
      icon: "Icon",

      styles: {
        text: {
          textcolor: "Text Color",
          background: "Background - Content",
          titleBackground: "Background - Title",
        },

        single: {
          textcolor: "Text Color",
          background: "Background - Content",
          titleBackground: "Background - Title",
          sparkline: "Sparkline",
          sparklineLabel: "Sparkline label",
        },

        color: {
          cardBackground: "Card Background",
          titleBackground: "Title Background",
          titleText: "Title Text",
          valueBackground: "Value Background",
          valueText: "Value Text",
        },
      },

      fontStyleTypes: {
        title: "Title",
        value: "Value",
        content: "Content",
      },

      keys: {
        so_own_axis: "Own Y-Axel",
        value_match: "Value name",
        icon: "Suffix icon",
        sufix: "Suffix",
        prefix: "Prefix",
        deveui: "Node",
        maxheight: "Max height",
        scriptid: "Script ID",
        target: "Target",
        tagkey: "Sensor",
        tagkey_x: "Sensor X",
        tagkey_y: "Sensor Y",
        tagkey_x_max: "Sensor X Max",
        tagkey_y_max: "Sensor X Min",
        forceColors: "Force colors",
        decimal_rounding: "Decimal rounding",
        dashboard_id: "Dashboard ID",
        sync_y: "Synchronize Y-axis",
        zoom_slider: "Zoom Slider",
        digital: "Squarewave",
        alarm_id: "Alarmlimits",
        so_chart_type: "Chart Type",
        so_ignore_body: "Only title",
        min_value: "Minimum Value",
        max_value: "Maximum Value",
        axis_label: "Axis Label",
        default_timespan: "Default Timespan",
        font_size: "Font Size",
        hide_trend_icon: "Hide Trend Icon",
        horizontel_single_title: "Horizontal title",
        so_hide_node_info: "Hide Node Information",
        font_style_large: "Font Style",
        so_single_sparkline: "Sparkline",
        gauge_180: "180° Gauge",
        button_label: "Button Label",
        button_label_on: "Label On-button",
        button_label_off: "Label Off-button",
        hide_title: "Hide Title",
        so_slots_count: "Amount of Slots to display",
        font_style_title: "Font Style for Title",
        font_style_content: "Typsnitt for content",
      },

      fields: {
        value_match: "Value",
        name: "Name",
        icon: "Suffix icon",
        sufix: "Suffix",
        prefix: "Prefix",
        deveui: "Node",
        maxheight: "Max height (e.g. 300 for 300px)",
        scriptid: "Script ID",
        target: "Target",
        tagkey: "Sensor",
        tagkey_x: "Sensor X",
        tagkey_y: "Sensor Y",
        tagkey_x_max: "Sensor X Max",
        tagkey_y_max: "Sensor X Min",
        forceColors: "Force colors",
        decimal_rounding: "Digits",
        dashboard_id: "Dashboard ID",
        sync_y: "Synchronize Y-axis",
        zoom_slider: "Show Zoom Slider",
        digital: "Squarewave",
        alarm_id: "Alarmlimits",
        chart_type: "Chart Type",
        ignoreBody: "Only title",
        min_value: "Minimum Value",
        max_value: "Maximum Value",
        axis_label: "Axis Label",
        default_timespan: "Default Timespan",
        so_font_style_title: "Font Style for Title",
        so_font_style_content: "Font Style for Content",
        font_style_large: {
          type: "Type",
          size: "Size",
          weight: "Weight",
        },
      },

      desc: {
        forceColors:
          "Force the selected colors to take precedence over alarm colors",
      },
    },

    fields: {
      title: "Title",
      types: "Type",
      content: "Content",
      sm: "Size on small screen",
      md: "Size on mid-sized screen",
      min: "Min value",
      max: "Max value",
      image: "Image",
      url: "URL",
      sync_chart: `Synchronize all series on the X-axis
(when you have widely differing data formats)`,
    },

    enum: {
      size: { xs: "XSMALL", sm: "SMALL", md: "MEDIUM", lg: "LARGE" },
      types: {
        0: "Chart",
        1: "Gauge",
        2: "Text",
        3: "Image",
        4: "Google Map",
        5: "Company information",
        6: "IFrame",
        7: "SMHI",
        8: "Latest value",
        9: "Raw table",
        10: "Chart",
        11: "Image map",
        12: "Function Button",
        13: "Node-Info",
        14: "Trend Chart",
        15: "Node-Overview",
        16: "360° Tilt Visualizer",
        17: "Fill Level Indicator",
        18: "Value Setting Sliders",
        19: "Camera",

        desc: {
          0: "Displays a graph",
          1: "Displays a gauge",
          2: "Displays text",
          3: "Displays a image",
          4: "Displays a map",
          5: "Displays a company information",
          6: "Displays a IFrame",
          7: "Displays SMHI weather data",
          8: "Displays the latest value",
          9: "Displays a raw-value table",
          10: "Displays a graph",
          11: "Displays a image-map",
          12: "Displays function button",
          13: "Displays node information",
          14: "Displays consumption graph",
          15: "Displays a node-overview",
          16: "Displays 360°-tilt viewer",
          17: "Displays fill-level",
          18: "Displays target level meter",
          19: "Displays camera stream from CamOnline",
        },
      },
    },

    functionButtonTypes: {
      script: "Script Execution",
      input: "Direct Value Input",
      navigation: "Dashboard Link",
      downlink: "Downlinks",
      onoffSwitch: "ON/OFF switch",
    },
  },

  weather: {
    title: "SMHI Weather forecast",
    icon: "Weather",
    temp: "Temperature",
    windDir: "Wind direction",
    windSpeed: "Wind speed",
    humidity: "Moisture",
    thunder: "Thunder",
    date: "Date",
    precipitation: "Precipitation",
  },

  contact: {
    title: "Contact us",
    descriptionTitle: "Sensor-Online",
    description:
      "We know it can be hard to get started, Dont hesitate to contact us and we will help answer your questions",
    emailTitle: "Email",
    email: "info@sensor-online.se",
    phoneTitle: "Phone",
    phoneOne: "+46 (0) 500 6000 22",
    phoneTwo: "+46 (0) 733 165 100",
    locationSiencepark: "Skövde Science Park",
    locationStreet: "Kaplansgatan 16B",
    locationPostalcode: "521 32 Skövde",
    locationCountry: "Sweden",
  },

  contacts: {
    title: "Contacts",
    selectContact: "Choose contact",
    selectContacts: "Choose contacts",

    fields: {
      name: "Name",
      email: "Email",
      message: "Message",
    },

    validation: {
      emailFormatError: "You must enter a valid email",
    },
  },

  company: {
    name: "Company name",
    orgNumber: "Organization number",
    associatedCompanies: "Associated companies",
    externalUsers: "External users",

    companyInfo: "Company information",

    addUser: "Add new user",

    invite: "Invite new user",
    inviteSent: "Invitation sent",
    inviteFailed:
      "Could not find the user, Make sure the user is registrered before!",

    shareApp: "Share Application",

    whiteLabelLogo: "Company logo",

    fields: {
      name: "Company name",
      orgNumber: "Organization number",
      postalAdress: "Postal adress",
      deliveryAdress: "Delivery adress",
      contactPhonenumber: "Contact phone number",
      invoiceAdress: "Email for contact",
      refrenceNmuber: "Reference number",
      image: "Company logotype",
    },

    validation: {
      nameRequired: "You must enter a company name",
      nameMax: "Company name must not be more than 255 characters",

      orgNumberRequired: "You have to fill in you company VAT number",
      orgNumberFormatError: "Wrong format VAT number",

      emailRequired: "You have to fill in email address",
      emailFormatError: "Add valid email adress",

      passwordMissmatch: "Passwords do not match",
    },
  },

  report: {
    title: "Reports",
    refrenceDateDesc:
      "The reference day is the day referred to when sending a report, if the interval is monthly, select the day on which it is sent (1st of each month, for example). Or weekly (where day is Mon - Fri for when the report is to be sent out).",
    create: "Create report subscription",
    update: "Update report subscription",
    exportDescription:
      "Export the report to the selected fileformat with the selected resources by a custom timespan",
    exportDownload: "Report download",
    export: "Export",
    errorRange: "Date range is invalid",
    downloadFailed: "The report could not be fetched, try again later!",
    noData: "There was no data found in the selected time span",
    fields: {
      name: "Report name",
      type: "Type",
      tempo: "Interval",
      createdAt: "Created",
      updatedAt: "Updated",
      id: "ID",
      refrenceDate: "Reference day",
      reportContacts: "Report contacts",
      lastSent: "Last Sent",
      template: "Template",
      perResourceReports: "Per resource reports",
      manualGeneration: "Manual export",
    },

    hints: {
      perResourceReports:
        "Activate this if you want to generate separate reports for each resource instead of a combined report with all the selected resources. Some resource Types may be forced to be per resource reports.",
      user: "Select the user that should be set as responsible for the generation of the report, this can be set to empty to use the system as the responsible user for the report. (This user will be used in some template reports to show who generated the report)",
    },

    tempo: {
      desc: {
        0: "Send the daily report from now on at 03:00",
        1: "Send the weekly report on mondays with the previous week's data.",
        2: "Send the monthly report on the 1st day of the month for the previous month.",
      },

      0: "Daily",
      1: "Weekly",
      2: "Monthly",
    },

    types: {
      regular: "Regular",
      trend: "Trend",
      template: "Template report",
    },

    exportTypes: {
      0: "Excel",
      1: "PDF",
      2: "CSV",
    },

    template: {
      title: "Report templates",
      create: "Upload a new Report template",
      hint: "The report template is used to create a report using Excel templates",
      uploadFileError: "Could not upload the file, try again later",
      updateSuccess: "Successfully updated the template",
      fields: {
        name: "Name",
        description: "Description",
        resources: "Resources",
        file: "Template",
        exportType: "File type for export",
      },
    },

    history: {
      title: "Report history",
      success: "Successful",
      message: "Report generation message",
    },
  },

  script: {
    name: "Scripts",
    create: "Create a new Script",
    edit: "Edit Script",
    tagsTitle: "Node ID and Sensors",
    placeHolderContent: `   // Convert the temperature to fahrenheit 
    var tempToFahrenheit = Data.Read("INSERT-DEVEUI", "INSERT-KEY") *  9/5 + 32;

    // Write the new value to a new key
    Data.Write("INSERT-DEVEUI", "INSERT-KEY", tempToFahrenheit);`,
    examples: "Examples",
    helpTitle: "Examples and help",

    type: {
      0: "Looping Script",
      1: "Invoked Script",
      2: "Triggered Script",
    },

    fields: {
      name: "Name",
      type: "Type",
      interval: "Interval",
      lastRun: "Last run time",
      enabled: "Enabled",
      boundTag: "Triggering tag",
    },

    validation: {
      intervalRequired: "This field must be filled",
      intervalMinValue: "The minimum value for the interval is 0",
    },
  },

  user: {
    name: "User",

    email: "Email",
    role: "Role",

    userInfo: "Personal information",

    loginFailed: "Login failed",
    signInFailed: "Email/password do not match try again.",
    signInError: "Unexpected error during login",
    loginSuccessful: "Logged in",
    updateFailed: "Update failed",
    edit: "Edit",
    forgotPassword: "Forgot password?",

    fields: {
      firstName: "First name",
      lastName: "Last name",
      phoneNumber: "Phone number",
      email: "Email",
      password: "Password",
      newPassword: "New Password",
      passwordConfirm: "Confirm Password",

      phoneNumerHint:
        "Hint: Use international formatting. Ex for a Swedish number (46703292943)",
    },
  },

  application: {
    name: "Application | Applications",
    noCompanyError: "You must register a company first to create applications",
    applicationCreated: "Your new application has been created",
    choose: "Choose a Application",
    create: "Create a new Application",
    validation: {
      nameRequired: "Your application must have a name",
      nameMax: "Name must be fewer than 255 characters",
    },
    fields: {
      id: "ID",
      name: "Name",
      createdAt: "Created",
      owner: "Owner",
    },
  },

  alarm: {
    name: "Alarm",
    create: "Add new alarm rule",
    affirmation: "Confirm",
    history: "Alarm history",
    acknowledgeButton: "Click to Acknowledge",
    activeAlarm: "Alarm warnings",

    test: {
      title: "Test of settings",
      settings: "Test email and sms settings",
      email: "Email address",
      phonenumber: "Phonenumber (46....)",
    },

    priority: {
      desc: {
        a: "High",
        b: "Medium",
        c: "Low",
      },
    },

    alertType: {
      desc: {
        a: "Send Email + SMS + Log",
        b: "Send SMS + Log",
        c: "Send Email + Log",
        d: "Send to Log only",
      },
    },

    fields: {
      id: "ID",
      name: "Name",
      alertType: "Alarm notification method",
      priority: "Priority",
      alarmDelay: "Delay time before alarm is raised",
      autoAcknowledge: "Auto-Acknowledge",
      active: "On/Off",
      tagDataKey: "Sensor",
      tag: "Node",
      message: "Message Content",
      lH: "HT",
      lL: "LT",
      limitHigh: "High Threshold",
      limitLow: "Low Threshold",
      contactType: "Contact type",
      alarmContacts: "Alarm contacts",
      activeDays: "Days activated",
      limit: "Low and high alarm thresholds",
      activeAlarms: "Actions",
      messageSent: "Message Sent",
      reset: "Reset",
      triggered: "Triggered",
      user: "Reset By",
      value: "Triggering Value",
      signalType: "Signal Type",
      digitalTrigger: "Trigger On",
      triggerHigh: "Signal High",
      triggerLow: "Signal Low",
      advanced: "Advanced",
      alarmComment: "Acknowledge Reason",
      logicalOperator: "Operator",
    },

    validation: {
      nameRequired: "This field must be filled in.",
      max: "Cannot exceed {count} characters.",
      min: "Must be at least {count} characters long.",
      noEmptySpaces: "Cannot be empty or just spaces.",
      noWhitespaceOnly: "Cannot consist of only whitespace.",
      noExcessiveRepetition: "Please avoid excessive repetition of characters.",
      containsLetter: "Must contain at least one letter.",
      notOnlySymbols: "Cannot consist of only symbols.",
    },

    tabs: {
      triggered: "Triggered Alarms",
      allAlarms: "All Alarms",
      history: "Alarm History",
    },
  },

  batteryStatus: {
    levels: "Battery level",
    value: "Value",
    lastSeen: "The latest value",
    label: "Battery voltage",
  },

  tagdata: {
    name: "Data",
    amount: "Number of data points collected:",
    notation: "{0}",
    latestactivity: "Latest activity",
    latestvalue: "Latest value",
    defaultKey: "Standard key",

    fields: {
      id: "ID",
      name: "Name",
      key: "Sensor",
      value: "Value",
      createdAt: "Created",
      label: "Label",
      publishKey: "Publish Sensor",
    },
  },

  tag: {
    sensor: {
      title: "Sensors",
      add: "Add new sensor",
    },
    choose: "Choose Node",
    search: "Search Node",
    searchDecoder: "Search Decoder",
    searchApp: "Search Application",
    chooseDecoder: "Choose a decoder",
    decoder: "Decoder",
    name: "Nodes",
    amount: "Number of nodes:",
    notation: "{0}",
    details: "Sensor details",
    removeImage: "Delete image",
    chooseKey: "Choose key",
    saveKeyAsDefault: "Default",
    newKey: "New Sensor",
    filter: "Filter Nodes",
    generateId: "Generate ID",

    uploadExplanation: "Drag your Csv file or click here to upload Nodes",
    uploadExplanationHint: "You can download a example csv file here: ",
    exampleLink: "CSV Example",

    errorTogglePublishKey:
      "Something went wrong when trying to toggle publishing",

    publishing: {
      title: "Publishing",
      reset: {
        0: "Reset MQTT Publishing",
        1: "Reset Sparkplug Publishing",
        2: "Reset Modbus Publishing",
        3: "Reset OPC-UA Publishing",
      },

      mqtt: "MQTT",
      modbus: "Modbus",
      sparkplug: "Sparkplug",
      opc: "OPC-UA",
    },

    tabs: {
      settings: "Settings",
      sensors: "Sensors",
      data: "Data",
    },

    log: {
      title: "Log",
      events: "Event",
      picker: "Select Option",
      journal: {
        title: "Journal",
        entryTitle: "Add Comment or Image",
        commentPlaceholder: "Write a comment ...",
        chooseFile: "Choose File",
        journalEntry: "Add Entry",
        journalEntries: "Journal Entries",
        comment: "Comment",
        file: "File",
        files: "Files",
        alert: "This journal has no attached files.",
        updateEntry: "Update Entry",
        updatedAt: "Updated",
        createdAt: "Created",
        error: {
          general: "An unexpected error occurred.",
          uploadFileFailed: "Failed to upload file.",
          addJournalFailed: "Failed to add journal entry.",
          noChanges: "No changes to update",
          deleteJournalFail: "Failed to delete entry",
        },
        success: {
          journalAdded: "Journal entry added successfully.",
          journalUpdated: "Journal entry updated",
          journalDeleted: "Journal deleted successfully",
        },
      },
    },

    createSteps: {
      step1: "Type of Node",
      step2: "Choose Application",
      step3: "Choose Decoder",
      step4: "Set Keys",
      step5: "MQTT",
      step6: "Attributes",
      finish: "Done",

      spotIo4: "SpotIO selection",
      modbus4: "Modbus",
      opc: "OPC-UA",
    },

    picker: {
      step1: "Select a Node",
      step2: "Select a Sensor",
    },

    tagPool: {
      tagPool: "Nodes pool",
      deveui: "Deveui",
      createdAt: "Created",
      hide: "Hide from list",
      show: "Show in default list",
      hiddenTags: "Hidden nodes",
      take: "Add sensor",
    },

    fields: {
      id: "ID",
      name: "Name",
      deveui: "DevEUI",
      appKey: "AppKey",
      appEui: "AppEUI",
      applicationId: "Application",
      profile: "Profile",
      lat: "Latitude",
      long: "Longitude",
      latAndLng: "Latitude and Longitude",
      image: "Image of sensor installation",
      battery: "Battery Level",
      activeAlarm: "Alarm",
      activeData: "Active",
      latestValue: "Latest Value",
      location: "Location",
      dataPoints: "Datapoints/24h",
      type: "Type",
      nodeType: "Node Type",
      attributes: "Attributes",
    },

    nodeTypes: {
      0: "LORA",
      1: "API",
      2: "MQTT",
      3: "NR",
      4: "SPARKPLUG",
      5: "SpotIO",
      6: "Modbus",
      7: "Opc-UA",
    },

    attributes: {
      name: "Name",
      value: "Value",
      title: "Attributes",
      titleSingular: "Attribute",
    },
  },

  license: {
    usedGateways: "Total used Gateways in License",
    usedNodes: "Total used Nodes in License",
    editMaxTitle: "Edit limits",
    companySettings: "Account settings",
    licenseManagement: "License management",
    publicKey: "Public key",
    upload: "Upload a new License file",
    uploadHint: "Drag and drop you'r new License-file",
    license: "License",
    licensee: "License Holder",
    coveredFeatures: "Covered Features",
    fields: {
      email: "Email",
      name: "Name",
      maxCompanies: "Max Companies",
      maxGateways: "Max Gateways",
      maxNodes: "Max Nodes",
      decoderIncluded: "Decoder Included",
      whiteLabel: "White-Label",

      licenseId: "License ID",
      status: "Status",
      publicKey: "Public Key",
      expires: "Expires",
    },

    limitations: {
      fields: {
        companyId: "Account ID",
        companyName: "Account",
        maxGateways: "Max Gateways (0 = Unlimited)",
        maxNodes: "Max Nodes (0 = Unlimited)",
        updatedAt: "Updated",

        currentNodes: "Current Noder",
        currentGateways: "Current Gateways",
      },

      errors: {
        gateway:
          "License does't allow for more Gateways, contact your distributer to extend",
        tag: "License does't allow for more Nodes, contact your distributer to extend",
        company:
          "License does't allow for more Accounts, contact your distributer to extend",
        expiresIn:
          "License expires in {0} days, contact your distributer to extend",
      },
    },

    errors: {
      couldNotSetLimits: "Could not set the limits for the selected Account",
    },

    tos: {
      title: "License Agreement",
      description: "Read and accept the license agreement to proceed to login",
      accept: "Accept",
      checkbox: "Accept License Agreement",
    },
  },

  validation: {
    required: "{0} must be filled in",
    max: "{0} must be {1} characters",
    requiredSingle: "This field must be filled in",
    toLong: "This field contains too many characters",
    contryCodeError:
      "Don't forget that the phone number must have country code (e.g. 4607xxxxxxxx)",
    passwordMissmatch: "Passwords do not match",
    deveuiConflict: "Node with this DevEUI already exists on this server",
    passwordLength: "Password is required to be atleast 6 characters long",
    passwordUpperAndLower:
      "Password must contain atleast one big and one small letter",
  },

  warning: {
    amount: "Active Alarms:",
    notation: "{0}",
  },

  mqtt: {
    settingsTabTitle: "MQTT Brokers",
    brokerListTitle: "MQTT Brokers",
    createTitle: "Add a new MQTT Broker",
    updateTitle: "Update MQTT Broker",
    selectBroker: "Select a MQTT Broker",
    testBroker: "Test Configuration",
    topicCreateTitle: "MQTT Topics",
    topicDescription:
      "The topics selected will be subscribed to through the configured broker. The topics should be specific to the device, if wildcard is used (+) it should be a single level wildcard. Multilevel wildcards (#) will be transformed into single level wildcards.",
    topicAdd: "Add a new Topic",
    topicAlreadyExists: "The selected topic already exists",
    createTopicTitle: "Create a new Subscription",

    sparkplug: {
      use: "Use Sparkplug Publishing",
      meta: {
        hint: {
          edgeId: "Sparkplug edge id",
          groupId: "Sparkplug group id",
        },

        edgeId: "Edge ID",
        groupId: "Grupp ID",
        useNameForPublish: "Use name instead of NodeID",
      },
    },

    meta: {
      fields: {
        topic: "Topic",
        publishTopic: "Publish Topic (Optional)",
        publishTopicHint: "Cannot be the same as any of the Subscribe Topics",
        parsingTopic: "Decoder Type",
        payloadTest: "Payload to test",
        testTopic: "Expected topic in Decode function",
      },
    },

    sslmodes: {
      0: "None",
      1: "CA Signed server certificate",
      2: "Self-signed server certificate",
    },

    versions: {
      0: "3.1.1",
      1: "3.1.0",
    },

    validation: {
      notfound: "Could not find Broker",
    },

    fields: {
      status: "Status",
      hostname: "Hostname",
      port: "Port",
      version: "MQTT Version",
      sslmode: "SSL/TLS Mode",
      CACertificate: "CA Certificate",
      ClientCertificate: "Client Certificate",
      ClientKey: "Client Key",
      KeyPassPhrase: "Key Passphrase (Optional)",
      useAuth: "Use Authentication",
      username: "Username",
      password: "Password",
      isGlobal: "Is Global Broker",

      metrics: {
        messages: {
          total: "Total Messages",
          week: "Messages this Week",
          day: "Messages this Day",
        },

        publish: {
          total: "Total Published",
          week: "Week Published",
          day: "Day Published",
        },
      },
    },
  },
};
